<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} themes</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">Found {{meta.total}} themes that match '{{query}}'</span>
      <router-link
        v-if="can(uiPermissions.THEMES_CREATE)"
        to="/themes/new"
        data-test="btn-new-theme"
        class="btn btn-primary ml-auto d-flex align-items-center">
        <i class="fe fe-plus mr-2" style="font-size: 1rem"/> Create theme
      </router-link>
    </div>
    <div class="row">

      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <dimmer :active="listLoading">
              <table data-test="table-theme" class="table table-hover table-outline table-vcenter text-nowrap card-table">
                <thead>
                  <tr>
                    <th class="w-1"></th>
                    <th>Name</th>
                    <th class="text-center w-1">Status</th>
                    <th class="text-center w-1">Last updated</th>
                    <th class="w-1"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-center">
                      <a
                        v-if="item.mobile_banner"
                        :href="item.mobile_banner.url"
                        target="_blank"
                        class="position-relative d-inline-block avatar"
                        rel="noreferrer noopener">
                        <img
                          :src="getTransformationUrl(item.mobile_banner, {height: 180, width: 180, crop: 'fill'})"
                          class="avatar"/>
                      </a>
                      <span
                        v-else
                        class="avatar"
                        :style="`background-image: url(${identicon(item.id)});`"></span>
                    </td>
                    <td>
                      <div>
                        <router-link :to="`/themes/${item.id}`">
                          {{item.name}}
                        </router-link>
                      </div>
                    </td>
                    <td class="text-center text-capitalize">
                      {{item.status}}
                    </td>
                    <td class="text-center">
                      {{moment.utc(item.updated_at).local().fromNow()}}
                    </td>
                    <td class="text-center">
                      <div v-if="can([uiPermissions.THEMES_UPDATE, uiPermissions.THEMES_DELETE])" class="item-action dropdown">
                        <a
                          tabindex="0"
                          data-toggle="dropdown"
                          data-test="link-dropdown"
                          class="icon"><i class="fe fe-more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <router-link
                            v-if="can(uiPermissions.THEMES_UPDATE)"
                            :to="`/themes/${item.id}`"
                            data-test="link-edit"
                            class="dropdown-item">
                            <i class="dropdown-icon fe fe-edit"></i> Edit
                          </router-link>
                          <template v-if="can(uiPermissions.THEMES_DELETE)">
                            <div class="dropdown-divider"></div>
                            <button
                              type="button"
                              data-test="btn-delete"
                              class="dropdown-item text-danger"
                              @click="handleDelete(item.id)">
                              <i class="dropdown-icon fe fe-trash text-danger"></i> Delete
                            </button>
                          </template>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="items.length === 0">
                    <td colspan="5">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </dimmer>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {themes} from '@/services';

export default {
  components: {
    Pagination,
  },
  mixins: [
    list,
  ],
  data: function() {
    return {
      withRelations: [
        'mobileBanner',
      ],
    };
  },
  methods: {
    fetchData(page) {
      return themes.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
        with: this.withRelations.join(','),
      });
    },
    async handleDelete(id) {
      if (window.confirm('Deleting theme cannot be undone. Are you sure that you want to delete this theme?')) {
        await themes.deleteById(id);
        this.refresh();
      }
    },
  },
};
</script>
